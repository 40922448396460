import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Pagination,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { BiSolidPhoneIncoming } from 'react-icons/bi';
import PropTypes from 'prop-types';

import { IoCopyOutline, IoPlayCircle } from 'react-icons/io5';
import axios from 'axios';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  AdminSystemStatus,
  CallCardStatus,
  CallSource,
  CallType,
  checkListingStatus,
  CurrencyType,
  PrivateListingPackageBackgroundColor,
  PrivateListingPackageName,
  GetCallCardSubjectTitle,
  getStatusColor,
  PermissionKeys,
  PrivateAdDraftTypes,
  PrivateAdTableType,
  PrivateAdTypes,
} from '@crema/constants/AppEnums';
import { CommaSeparator } from '@crema/helpers/Extensions';
import { BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { UserHasPermission } from '@crema/helpers/RouteHelper';
import { useUserPermissions } from '@crema/context/AppContextProvider/PermissionContextProvider';
import { MdPhoneMissed } from 'react-icons/md';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { formatRelativeDate } from '@crema/helpers/DateHelper';

const CallHistory = ({ callHistory }) => {
  const { user } = useAuthUser();
  const { userPermissions } = useUserPermissions();
  const { showSnackbar } = useGlobal();

  const hasPlayCallRecordingPermission = UserHasPermission(
    PermissionKeys.GeneralPermissionGroup.PlayCallRecording,
    userPermissions,
  );

  const [loadingAudio, setLoadingAduio] = useState(false);
  const [audioFileUrl, setAudioFileUrl] = useState();
  const [selectedCallCdrId, setSelectedCallCdrId] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const handlePlayCallAudio = (callCdrId) => {
    setAudioFileUrl();
    setSelectedCallCdrId(callCdrId);
    setLoadingAduio(true);
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Call/play-cdr`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      params: {
        id: callCdrId,
        urlOnly: true,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setAudioFileUrl(response.data.downloadRecordingUrl);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingAduio(false);
      });
  };

  // Pagination Logic
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedCallHistory = callHistory.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Box
      sx={{
        mx: 4,
        mt: 4,
      }}
    >
      {paginatedCallHistory.length > 0 && (
        <Box
          sx={{
            background: '#F5F7F8',
            borderRadius: 2,
            p: 2,
            mt: 2,
          }}
        >
          <Box>
            <Typography variant='h4'>Call History</Typography>

            {paginatedCallHistory?.map((CallItem, index) =>
              CallItem.PrivateAdTableTypeId != 0 ? (
                <Card
                  key={index}
                  sx={{
                    width: '100%',
                    // background: '#F5F7F8',
                    mt: 2,
                    px: 4,
                    py: 2,
                    borderRadius: 2,
                    boxShadow: 'none',
                  }}
                  variant='outlined'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box>
                      {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && (
                        <Box sx={{ display: 'flex', gap: 2, fontWeight: 600 }}>
                          <Typography variant='h5' sx={{ fontWeight: 600 }}>
                            {CallItem?.Car?.Brand['BrandNameen']} {CallItem?.Car?.Model?.['ModelNameen']}{' '}
                            {CallItem?.Car?.Year.YearName}
                          </Typography>
                          -
                          <Typography variant='h5' sx={{ fontWeight: 600, color: 'red' }}>
                            {CallItem.Car.PriceUnit == CurrencyType.Dollar && CallItem.Car.Price > 0
                              ? '$' + CommaSeparator(CallItem.Car.Price)
                              : ''}
                            {CallItem.Car.PriceUnit == CurrencyType.IraqiDinar && CallItem.Car.PriceIQD > 0
                              ? 'IQD ' + CommaSeparator(CallItem.Car.PriceIQD)
                              : ''}
                          </Typography>
                        </Box>
                      )}
                      {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable && (
                        <Box sx={{ display: 'flex', gap: 2, fontWeight: 600 }}>
                          <Typography variant='h5' sx={{ fontWeight: 600 }}>
                            {CallItem?.Draft?.Brand['BrandNameen']} {CallItem?.Draft?.Model?.['ModelNameen']}{' '}
                            {CallItem?.Draft?.Year.YearName}
                          </Typography>
                          -
                          <Typography variant='h5' sx={{ fontWeight: 600, color: 'red' }}>
                            {CallItem.Draft.PriceUnit == CurrencyType.Dollar && CallItem.Draft.Price > 0
                              ? '$' + CommaSeparator(CallItem.Draft.Price)
                              : ''}
                            {CallItem.Draft.PriceUnit == CurrencyType.IraqiDinar && CallItem.Draft.PriceIQD > 0
                              ? 'IQD ' + CommaSeparator(CallItem.Draft.PriceIQD)
                              : ''}
                          </Typography>
                        </Box>
                      )}
                      <Box sx={{ display: 'flex', gap: 2, mb: 0.5 }}>
                        <Box
                          sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            showSnackbar('Copied to clipboard', 'success');
                            navigator.clipboard.writeText(
                              (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable &&
                                CallItem.DraftId) ||
                                (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && CallItem.CarId),
                            );
                          }}
                        >
                          <Typography variant='h5' style={{ fontWeight: '400' }}>
                            {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable && CallItem.DraftId}
                            {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && CallItem.CarId}
                          </Typography>
                          <IoCopyOutline />
                        </Box>
                        <Divider orientation='vertical' flexItem sx={{ height: 'auto' }} />
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <span
                            style={{
                              display: 'inline-block',
                              width: '10px',
                              height: '10px',
                              backgroundColor: getStatusColor(
                                checkListingStatus(
                                  (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable &&
                                    CallItem.Draft) ||
                                    (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && CallItem.Car),
                                ),
                              ),
                              borderRadius: '50%',
                            }}
                          ></span>
                          <Typography>
                            {checkListingStatus(
                              (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable && CallItem.Draft) ||
                                (CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && CallItem.Car),
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ width: 100, mt: 0.5 }}>
                        {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable && (
                          <Typography
                            variant='h6'
                            sx={{
                              fontWeight: 600,
                              backgroundColor: PrivateListingPackageBackgroundColor(CallItem.Draft),
                              color: '#fff',
                              px: 2,
                              py: 0.5,
                              // borderRadius: 1,
                              textAlign: 'center',
                            }}
                          >
                            {PrivateListingPackageName(CallItem.Draft)}
                          </Typography>
                        )}
                        {CallItem.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable && (
                          <Typography
                            variant='h6'
                            sx={{
                              fontWeight: 600,
                              backgroundColor: PrivateListingPackageBackgroundColor(CallItem.Car),
                              color: '#fff',
                              px: 2,
                              py: 0.5,
                              // borderRadius: 1,
                              textAlign: 'center',
                            }}
                          >
                            {PrivateListingPackageName(CallItem.Car)}
                          </Typography>
                        )}
                      </Box>
                      {CallItem.CallSubject > 0 && (
                        <Box
                          sx={{
                            border: '1px solid #ccc',
                            py: 0.5,
                            px: 2,
                            borderRadius: 1,
                            width: 'fit-content',
                            mt: 1,
                          }}
                        >
                          <Typography variant='p' sx={{ fontSize: 12 }}>
                            {GetCallCardSubjectTitle(CallItem.CallSubject)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <Typography
                        variant='h6'
                        sx={{
                          fontWeight: '600',
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {CallItem?.ApplicationUser?.FullName}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        {CallItem.CallType == CallType.Inbound && CallItem.CallStatus == CallCardStatus.Answered && (
                          <BiSolidPhoneIncoming style={{ width: '1.3em', height: '1.3em', color: 'grey' }} />
                        )}
                        {CallItem.CallType == CallType.Inbound &&
                          (CallItem.CallStatus == CallCardStatus.NoAnswer ||
                            CallItem.CallStatus == CallCardStatus.Busy) && (
                            <MdPhoneMissed style={{ width: '1.2em', height: '1.2em', color: 'red' }} />
                          )}

                        {CallItem.CallType == CallType.Outbound && (
                          <BsFillTelephoneOutboundFill style={{ color: 'green' }} />
                        )}

                        <Typography variant='h6'>
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.Answered &&
                            'Incoming'}
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.NoAnswer &&
                            'Missed'}
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.Busy &&
                            'Rejected'}
                          {CallItem.CallType == CallType.Outbound && 'Outgoing'}
                        </Typography>
                        {CallItem.CallSource == CallSource.WhatsApp && <Typography variant='h6'>- WhatsApp</Typography>}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {CallItem.CallType == CallType.Outbound &&
                          (CallItem.CallStatus == CallCardStatus.NoAnswer ||
                            CallItem.CallStatus == CallCardStatus.Busy) && (
                            <Typography variant='h6'>No Answer</Typography>
                          )}
                        {CallItem.CallStatus != CallCardStatus.NoAnswer &&
                          CallItem.CallStatus != CallCardStatus.Busy && (
                            <Typography variant='h6'>{CallItem.CDR?.TalkDuration}</Typography>
                          )}
                      </Box>

                      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                        <Tooltip
                          title={new Date(CallItem?.SaveDate + 'Z').toLocaleString('en-GB', {
                            timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })}
                          arrow
                        >
                          <Typography variant='h6'> {formatRelativeDate(CallItem?.SaveDate)}</Typography>
                        </Tooltip>
                      </Box>
                      {CallItem.IsRecordingAvailable && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            width: '100%',
                            mt: 2,
                          }}
                        >
                          {audioFileUrl && selectedCallCdrId === CallItem.CDRId && (
                            <audio
                              controls
                              autoPlay
                              style={{
                                height: '30px',
                                maxWidth: '300px',
                                background: '#fff',
                              }}
                              controlsList='nodownload'
                            >
                              <source src={audioFileUrl} />
                            </audio>
                          )}
                          {selectedCallCdrId != CallItem.CDRId && !loadingAudio && hasPlayCallRecordingPermission && (
                            <Button
                              onClick={() => handlePlayCallAudio(CallItem.CDRId)}
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                width: '50px',
                                height: '25px',
                                background: '#222B36',
                                color: '#fff',
                                '&:hover': {
                                  background: '#222B36',
                                  '& svg': {
                                    color: '#fff',
                                  },
                                },
                              }}
                            >
                              <IoPlayCircle style={{ width: '1.5em', height: '1.5em' }} />
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {CallItem.Note && <Divider sx={{ my: 1 }} />}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {CallItem.Note && (
                      <Box>
                        <Typography variant='h5' sx={{ my: 0.5 }}>
                          Note
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 400 }}>
                          {CallItem.Note}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Card>
              ) : (
                <Card
                  key={index}
                  sx={{
                    width: '100%',
                    // background: '#F5F7F8',
                    mt: 2,
                    px: 4,
                    py: 2,
                    borderRadius: 2,
                    boxShadow: 'none',
                  }}
                  variant='outlined'
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Typography
                        variant='h6'
                        sx={{
                          fontWeight: '600',
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {CallItem?.ApplicationUser?.FullName}
                      </Typography>

                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Tooltip
                          title={new Date(CallItem?.SaveDate + 'Z').toLocaleString('en-GB', {
                            timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true,
                          })}
                          arrow
                        >
                          <Typography variant='h6'> {formatRelativeDate(CallItem?.SaveDate)}</Typography>
                        </Tooltip>
                      </Box>
                      {CallItem.CallSubject > 0 && (
                        <Box
                          sx={{
                            border: '1px solid #ccc',
                            py: 0.5,
                            px: 2,
                            borderRadius: 1,
                            width: 'fit-content',
                            mt: 1,
                          }}
                        >
                          <Typography variant='p' sx={{ fontSize: 12 }}>
                            {GetCallCardSubjectTitle(CallItem.CallSubject)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        {CallItem.CallType == CallType.Inbound && CallItem.CallStatus == CallCardStatus.Answered && (
                          <BiSolidPhoneIncoming style={{ width: '1.3em', height: '1.3em', color: 'grey' }} />
                        )}
                        {CallItem.CallType == CallType.Inbound &&
                          (CallItem.CallStatus == CallCardStatus.NoAnswer ||
                            CallItem.CallStatus == CallCardStatus.Busy) && (
                            <MdPhoneMissed style={{ width: '1.2em', height: '1.2em', color: 'red' }} />
                          )}

                        {CallItem.CallType == CallType.Outbound && (
                          <BsFillTelephoneOutboundFill style={{ color: 'green' }} />
                        )}

                        <Typography variant='h6'>
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.Answered &&
                            'Incoming'}
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.NoAnswer &&
                            'Missed'}
                          {CallItem.CallType == CallType.Inbound &&
                            CallItem.CallStatus == CallCardStatus.Busy &&
                            'Rejected'}
                          {CallItem.CallType == CallType.Outbound && 'Outgoing'}
                        </Typography>
                        {CallItem.CallSource == CallSource.WhatsApp && <Typography variant='h6'>- WhatsApp</Typography>}
                      </Box>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {CallItem.CallType == CallType.Outbound &&
                          (CallItem.CallStatus == CallCardStatus.NoAnswer ||
                            CallItem.CallStatus == CallCardStatus.Busy) && (
                            <Typography variant='h6'>No Answer</Typography>
                          )}
                        {CallItem.CallStatus != CallCardStatus.NoAnswer &&
                          CallItem.CallStatus != CallCardStatus.Busy && (
                            <Typography variant='h6'>{CallItem.CDR?.TalkDuration}</Typography>
                          )}
                      </Box>

                      {CallItem.IsRecordingAvailable && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            width: '100%',
                            mt: 2,
                          }}
                        >
                          {audioFileUrl && selectedCallCdrId === CallItem.CDRId && (
                            <audio
                              controls
                              autoPlay
                              style={{
                                height: '30px',
                                maxWidth: '300px',
                                background: '#fff',
                              }}
                              controlsList='nodownload'
                            >
                              <source src={audioFileUrl} />
                            </audio>
                          )}
                          {selectedCallCdrId != CallItem.CDRId && !loadingAudio && hasPlayCallRecordingPermission && (
                            <Button
                              onClick={() => handlePlayCallAudio(CallItem.CDRId)}
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                width: '50px',
                                height: '25px',
                                background: '#222B36',
                                color: '#fff',
                                '&:hover': {
                                  background: '#222B36',
                                  '& svg': {
                                    color: '#fff',
                                  },
                                },
                              }}
                            >
                              <IoPlayCircle style={{ width: '1.5em', height: '1.5em' }} />
                            </Button>
                          )}
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {CallItem.Note && <Divider sx={{ my: 1 }} />}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    {CallItem.Note && (
                      <Box>
                        <Typography variant='h5' sx={{ my: 0.5 }}>
                          Note
                        </Typography>
                        <Typography variant='h6' sx={{ fontWeight: 400 }}>
                          {CallItem.Note}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Card>
              ),
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <Pagination
              count={Math.ceil(callHistory?.length / itemsPerPage)}
              page={currentPage}
              onChange={handleChangePage}
              color='secondary'
              sx={{
                '& .MuiPaginationItem-root': {
                  '&.Mui-selected': {
                    backgroundColor: '#222B36',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#222B36', // Maintain the same color on hover
                    },
                  },
                },
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CallHistory;

CallHistory.propTypes = {
  callHistory: PropTypes.array,
};
