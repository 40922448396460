import {
  CallDictionaryKey,
  CallSourcesConfiguration,
  CallStatusConfiguration,
  CallType,
  PrivateAdTableType,
} from '@crema/constants/AppEnums';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { formatSpacePhoneNumber } from '@crema/helpers/Extensions';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { GoPerson } from 'react-icons/go';
import { IoCall, IoCallOutline, IoClose } from 'react-icons/io5';

const ManualCallCardDialog = () => {
  const { user } = useAuthUser();
  const {
    outboundCallCardSubjectTypesList,
    listingAndUserInfo,
    openManualCallCardDialog,
    setOpenManualCallCardDialog,
    showSnackbar,
    hideSnackbar,
    selectedCallDictionaryKey,
  } = useGlobal();

  const [isLoading, setIsLoading] = useState(false);

  const [manualCallCardNote, setManualCallCardNote] = useState('');

  const [selectedManualCallCardSubject, setSelectedManualCallCardSubject] = useState();

  const [selectedManualCallCardSource, setSelectedManualCallCardSource] = useState();

  const [selectedManualCallCardStatus, setSelectedManualCallCardStatus] = useState();

  const [manualCallCardError, setManualCallCardError] = useState(false);

  const handleSaveManualCallCard = () => {
    if (!selectedManualCallCardSubject || !selectedManualCallCardSource || !selectedManualCallCardStatus) {
      setManualCallCardError(true);
      return;
    }
    setIsLoading(true);
    setManualCallCardError(false);

    let manualCallCardData = {
      appUserId: listingAndUserInfo.AppUser.ID,
      phoneNumber: listingAndUserInfo.AppUser.PhoneNumber,
      callType: CallType.Outbound,
      callSource: selectedManualCallCardSource,
      callSubject: selectedManualCallCardSubject,
      callStatus: selectedManualCallCardStatus,
      CallCreationContext: selectedCallDictionaryKey,
      PrivateAdTableTypeId: listingAndUserInfo.PrivateAdTableTypeId,
    };

    if (listingAndUserInfo.PrivateAdTableTypeId == PrivateAdTableType.FromDraftTable) {
      manualCallCardData.DraftId = listingAndUserInfo.ID;
    } else if (listingAndUserInfo.PrivateAdTableTypeId == PrivateAdTableType.FromCarTable) {
      manualCallCardData.CarId = listingAndUserInfo.ID;
    }

    if (manualCallCardNote !== '') {
      manualCallCardData.note = manualCallCardNote;
    }

    const config = {
      method: 'post',
      url: `${process.env.React_App_Base_URL}/api/Call/create-manual-call-card`,
      data: manualCallCardData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.status == true) {
          showSnackbar('Call Saved', 'success');
          setOpenManualCallCardDialog(false);
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackbar('Failed to save', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      anchor='right'
      open={openManualCallCardDialog}
      onClose={() => setOpenManualCallCardDialog(false)}
      maxWidth='100%'
      maxHeight='100%'
    >
      <Box sx={{ width: '100%', overflow: 'auto', p: 4, minHeight: 300 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h3' sx={{ my: 1 }}>
            Create Manual Call
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setOpenManualCallCardDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IoClose />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Typography variant='h5' sx={{ mt: 3, fontWeight: '400' }}>
          Personal Information
        </Typography>

        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <GoPerson style={{ width: '1.5em', height: '1.5em' }} />
          {listingAndUserInfo?.AppUser.ContactName}
        </Typography>

        <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <IoCallOutline style={{ width: '1.5em', height: '1.5em' }} />

          {formatSpacePhoneNumber(listingAndUserInfo?.AppUser.PhoneNumber)}
        </Typography>

        <Typography variant='h5' sx={{ mt: 6, fontWeight: '400' }}>
          Select Call Source
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
          {CallSourcesConfiguration.map((callSourceItem, index) => (
            <Chip
              key={index}
              label={callSourceItem.Text}
              variant='outlined'
              size='large'
              sx={{
                background: callSourceItem.ID == selectedManualCallCardSource ? '#222B36' : '#fff',

                color: callSourceItem.ID == selectedManualCallCardSource ? '#fff' : '#222B36',

                '&.MuiChip-clickable:hover': {
                  background: callSourceItem.ID == selectedManualCallCardSource ? '#222B36' : '#0000000a',

                  color: callSourceItem.ID == selectedManualCallCardSource ? '#fff' : '#222B36',
                },
              }}
              onClick={() => setSelectedManualCallCardSource(callSourceItem.ID)}
            />
          ))}
        </Box>
        {!selectedManualCallCardSource && manualCallCardError && (
          <FormHelperText sx={{ color: 'red', mt: 2 }}>Call Source is Required</FormHelperText>
        )}

        <Typography variant='h5' sx={{ mt: 6, fontWeight: '400' }}>
          Select Call Subject
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
          {outboundCallCardSubjectTypesList[CallDictionaryKey.AdminSystemCRMSection].map(
            (OutboundCallCardSubject, index) => (
              <Chip
                key={index}
                label={OutboundCallCardSubject.Text}
                variant='outlined'
                size='large'
                sx={{
                  background: OutboundCallCardSubject.ID == selectedManualCallCardSubject ? '#222B36' : '#fff',

                  color: OutboundCallCardSubject.ID == selectedManualCallCardSubject ? '#fff' : '#222B36',

                  '&.MuiChip-clickable:hover': {
                    background: OutboundCallCardSubject.ID == selectedManualCallCardSubject ? '#222B36' : '#0000000a',

                    color: OutboundCallCardSubject.ID == selectedManualCallCardSubject ? '#fff' : '#222B36',
                  },
                }}
                onClick={() => setSelectedManualCallCardSubject(OutboundCallCardSubject.ID)}
              />
            ),
          )}
        </Box>
        {!selectedManualCallCardSubject && manualCallCardError && (
          <FormHelperText sx={{ color: 'red', mt: 2 }}>Call Subject is Required</FormHelperText>
        )}

        <Typography variant='h5' sx={{ mt: 6, fontWeight: '400' }}>
          Select Call Status
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
          {CallStatusConfiguration.map((callStatusItem, index) => (
            <Chip
              key={index}
              label={callStatusItem.Text}
              variant='outlined'
              size='large'
              sx={{
                background: callStatusItem.ID == selectedManualCallCardStatus ? '#222B36' : '#fff',

                color: callStatusItem.ID == selectedManualCallCardStatus ? '#fff' : '#222B36',

                '&.MuiChip-clickable:hover': {
                  background: callStatusItem.ID == selectedManualCallCardStatus ? '#222B36' : '#0000000a',

                  color: callStatusItem.ID == selectedManualCallCardStatus ? '#fff' : '#222B36',
                },
              }}
              onClick={() => setSelectedManualCallCardStatus(callStatusItem.ID)}
            />
          ))}
        </Box>
        {!selectedManualCallCardStatus && manualCallCardError && (
          <FormHelperText sx={{ color: 'red', mt: 2 }}>Call Status is Required</FormHelperText>
        )}
        <TextField
          id='outlined-multiline-static'
          label='Note'
          multiline
          rows={4}
          value={manualCallCardNote}
          onChange={(e) => setManualCallCardNote(e.target.value)}
          sx={{ width: '100%', mt: 6 }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 2,
          }}
        >
          {isLoading && (
            <Button
              variant='contained'
              disabled
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 4,
                width: '200px',
                gap: 2,
              }}
            >
              <CircularProgress size={18} color='inherit' />
              Save Call
            </Button>
          )}

          {!isLoading && (
            <Button
              onClick={handleSaveManualCallCard}
              type='submit'
              sx={{
                mt: 4,
                width: '150px',
                background: '#222B36',
                color: '#fff',
                '&:hover': {
                  background: '#222B36',
                  '& svg': {
                    color: '#fff',
                  },
                },
              }}
            >
              Save Call
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ManualCallCardDialog;
