import { CallType } from '@crema/constants/AppEnums';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  Drawer,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SlLocationPin } from 'react-icons/sl';
import { IoCall, IoCallOutline, IoClose, IoGlobeOutline, IoLocationOutline, IoPersonOutline } from 'react-icons/io5';
import CallHistory from './CallHistory';
import ListingHistory from './ListingHistory';
import AppLoader from '@crema/components/AppLoader';
import { GrLocation } from 'react-icons/gr';
import { LiaWalletSolid } from 'react-icons/lia';
import { FaRegCircleUser } from 'react-icons/fa6';
import { CiGlobe } from 'react-icons/ci';
import { GoPerson } from 'react-icons/go';
import { PiMapPinLight } from 'react-icons/pi';
import { formatSpacePhoneNumber } from '@crema/helpers/Extensions';

const InboundCallUserDetailsDialog = () => {
  const { user } = useAuthUser();
  const {
    openReceiveNewCallCardDialog,
    openInboundCallUserDetailsDialog,
    setOpenInboundCallUserDetailsDialog,
    inboundCallUserDetails,
  } = useGlobal();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();

  const getUserDetails = () => {
    let requestURLUserDetails;
    let requestParamsUserDetails;

    if (inboundCallUserDetails?.callerId) {
      requestURLUserDetails = `${process.env.React_App_Base_URL}/api/Home/registered-user-details`;
      requestParamsUserDetails = {
        appUserId: inboundCallUserDetails.callerId,
        callId: inboundCallUserDetails.callId,
      };
    } else {
      requestURLUserDetails = `${process.env.React_App_Base_URL}/api/Home/non-registered-user-details`;
      requestParamsUserDetails = {
        phoneNumber: inboundCallUserDetails.phoneNumber,
        callId: inboundCallUserDetails.callId,
      };
    }

    const config = {
      method: 'get',
      url: requestURLUserDetails,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      params: requestParamsUserDetails,
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setUserDetails(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (openInboundCallUserDetailsDialog) {
      getUserDetails();
    }
  }, []);

  return (
    <Drawer
      anchor='right'
      open={openInboundCallUserDetailsDialog}
      onClose={() => setOpenInboundCallUserDetailsDialog(false)}
      maxWidth='100%'
      maxHeight='100%'
    >
      {isLoading && !userDetails && <AppLoader />}

      {!isLoading && userDetails && (
        <Box sx={{ minWidth: 650 }}>
          <Box
            sx={{
              // background: '#f4f6f8',
              width: '100%',
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-start' }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 8,
                  alignItems: 'flex-start',
                }}
              >
                <Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                    <GoPerson style={{ width: '1.6em', height: '1.6em' }} />

                    <Typography
                      variant='h5'
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '75px',
                      }}
                    >
                      {userDetails?.UserInformation?.ContactName}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                    <IoCallOutline style={{ width: '1.6em', height: '1.6em' }} />
                    <Typography variant='h5'>
                      {formatSpacePhoneNumber(userDetails?.UserInformation?.PhoneNumber)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      mb: 1,
                    }}
                  >
                    <CiGlobe style={{ width: '1.6em', height: '1.6em' }} />

                    <Typography variant='h5'>
                      {' '}
                      {userDetails?.UserInformation?.LangCode == 'en' && 'English'}
                      {userDetails?.UserInformation?.LangCode == 'ar' && 'Arabic'}
                      {userDetails?.UserInformation?.LangCode == 'ku' && 'Kurdish'}
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                    <PiMapPinLight style={{ width: '1.4em', height: '1.4em' }} />

                    <Typography variant='h5'>{userDetails?.UserInformation?.Location?.['LocationNameen']}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <LiaWalletSolid style={{ width: '1.6em', height: '1.6em' }} />

                    <Typography variant='h5'>{userDetails?.UserInformation?.CreditAmount}</Typography>
                  </Box>
                </Box>
              </Box>
              <Divider orientation='vertical' flexItem />
              {userDetails?.UserPBXCallPrompts?.length > 0 && (
                <Box>
                  <Typography variant='h5' sx={{ mt: 5, fontWeight: '400' }}>
                    User Prompts
                  </Typography>
                  {userDetails?.UserPBXCallPrompts?.map((item) => (
                    <Typography key={item.ID} variant='h5' sx={{ mt: 2 }}>
                      {item?.IVR?.IVRTitle}: {item?.Prompt?.Texten}
                    </Typography>
                  ))}
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  onClick={() => setOpenInboundCallUserDetailsDialog(false)}
                  aria-label='close'
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <IoClose />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          {userDetails?.UserListingHistoryList && (
            <ListingHistory listingHistory={userDetails?.UserListingHistoryList} />
          )}
          {userDetails?.UserCallHistoryList && <CallHistory callHistory={userDetails?.UserCallHistoryList} />}
        </Box>
      )}
    </Drawer>
  );
};

export default InboundCallUserDetailsDialog;
