import React from 'react';
import { BiAlignLeft } from 'react-icons/bi';
import { HiUserGroup, HiUsers } from 'react-icons/hi2';
import { PermissionKeys, RoutePermittedRole } from '@crema/constants/AppEnums';
import { BsPersonFillGear, BsPersonFillLock } from 'react-icons/bs';
import { MdPendingActions } from 'react-icons/md';
import { TbListDetails } from 'react-icons/tb';
import { GoTasklist } from 'react-icons/go';
import { RiListSettingsLine } from 'react-icons/ri';
import { FaRegChartBar } from 'react-icons/fa6';
import { FaRegTrashAlt } from 'react-icons/fa';

const routesConfig = [
  {
    id: 'manageaccess',
    title: 'Manage Access',
    messageId: 'sidebar.manageaccess',
    requireSuperAdmin: true,
    type: 'collapse',
    icon: <BsPersonFillGear />,
    children: [
      {
        id: 'users',
        title: 'Users',
        messageId: 'sidebar.users',
        requireSuperAdmin: true,
        type: 'item',
        icon: <HiUsers />,
        url: '/manage-access/users',
      },
      {
        id: 'agents',
        title: 'Agents',
        messageId: 'sidebar.agents',
        requireSuperAdmin: true,

        type: 'item',
        icon: <HiUsers />,
        url: '/manage-access/agents',
      },
      {
        id: 'roles',
        title: 'Roles',
        messageId: 'sidebar.roles',
        requireSuperAdmin: true,
        type: 'item',
        icon: <BsPersonFillLock />,
        url: '/manage-access/roles',
      },
      {
        id: 'assignroles',
        title: 'Assign Roles',
        messageId: 'sidebar.assignroles',
        requireSuperAdmin: true,
        type: 'item',
        icon: <BsPersonFillLock />,
        url: '/manage-access/assign-roles',
      },
      {
        id: 'assigngroups',
        title: 'Ticket Assign Groups',
        requireSuperAdmin: true,
        messageId: 'sidebar.assigngroups',
        type: 'item',
        icon: <HiUserGroup />,
        url: '/manage-access/assign-groups',
      },
    ],
  },
  {
    id: 'managelistings',
    title: 'Manage Listings',
    messageId: 'sidebar.managelistings',
    permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
    requireSuperAdmin: false,
    type: 'item',
    icon: <RiListSettingsLine />,
    url: '/manage-listings',
  },
  // {
  //   id: 'manageshowrooms',
  //   title: 'Manage Showrooms',
  //   messageId: 'sidebar.manageshowrooms',
  //   // permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
  //   requireSuperAdmin: false,
  //   type: 'item',
  //   icon: <RiListSettingsLine />,
  //   url: '/manage-showrooms',
  // },
  {
    id: 'Tickets',
    title: 'Tickets',
    messageId: 'sidebar.tickets',
    permitedPermissionKey: PermissionKeys.TicketPermissionGroup.ViewTicketsPage,
    requireSuperAdmin: false,
    type: 'item',
    icon: <GoTasklist />,
    url: '/tickets/tasks',
  },
  {
    id: 'CRM Pending Listings',
    title: 'CRM Pending Listings',
    messageId: 'sidebar.crmpendinglistings',
    requireSuperAdmin: false,
    permitedPermissionKey: PermissionKeys.CRMListingsPermissionGroup.ViewCRMListingsPage,
    type: 'item',
    icon: <MdPendingActions />,
    url: '/crm/pending-listings',
  },
  {
    id: 'Editor Pending Listings',
    title: 'Editor Pending Listings',
    messageId: 'sidebar.editorpendinglistings',
    requireSuperAdmin: false,
    permitedPermissionKey: PermissionKeys.EditorPendingListingsPermissionGroup.ViewPendingListingsPage,
    type: 'item',
    icon: <MdPendingActions />,
    url: '/editor/pending-listings',
  },

  {
    id: 'analytics',
    title: 'Analytics',
    messageId: 'sidebar.analytics',
    requireSuperAdmin: false,
    permitedPermissionKey: [
      PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemAnalytics,
      PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemListingReviewProcess,
    ],
    type: 'collapse',
    icon: <FaRegChartBar />,
    children: [
      {
        id: 'Tickets Analyticis',
        title: 'Tickets Analyticis',
        messageId: 'sidebar.ticketsanalytics',
        requireSuperAdmin: false,
        permitedPermissionKey: PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemAnalytics,
        type: 'item',
        url: '/analytics/ticket-analytics',
      },
      {
        id: 'Listing Review Process',
        title: 'Listing Review Process',
        messageId: 'sidebar.listingreviewprocess',
        requireSuperAdmin: false,
        permitedPermissionKey: PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemListingReviewProcess,
        type: 'item',
        url: '/analytics/listing-review-process',
      },
    ],
  },
  {
    id: 'bin',
    title: 'Bin',
    messageId: 'sidebar.bin',
    requireSuperAdmin: false,
    permitedPermissionKey: [
      PermissionKeys.CRMListingsPermissionGroup.ViewDiscardedDrafts,
      PermissionKeys.TicketPermissionGroup.ReadDiscardedTickets,
    ],
    type: 'collapse',
    icon: <FaRegTrashAlt />,
    children: [
      {
        id: 'Discarded Listings',
        title: 'Discarded Listings',
        messageId: 'sidebar.discardedlistings',
        permitedPermissionKey: PermissionKeys.CRMListingsPermissionGroup.ViewDiscardedDrafts,
        requireSuperAdmin: false,
        type: 'item',
        url: '/discarded/discarded-listings',
      },
      {
        id: 'Discarded Tickets',
        title: 'Discarded Tickets',
        messageId: 'sidebar.discardedtickets',
        permitedPermissionKey: PermissionKeys.TicketPermissionGroup.ReadDiscardedTickets,
        requireSuperAdmin: false,
        type: 'item',
        url: '/discarded/discarded-tickets',
      },
    ],
  },
];
export default routesConfig;
