import { formatDistanceToNow } from 'date-fns';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat'; // load on demand
import relativeTime from 'dayjs/plugin/relativeTime'; // load on demand

dayjs.extend(AdvancedFormat); // use plugin
dayjs.extend(relativeTime); // use plugin

export const getDateObject = (dateObject) => {
  if (dateObject) return dayjs(dateObject);
  return dayjs();
};

export const getFormattedDate = (dateObject, format = 'MMM DD,YYYY') => {
  if (dateObject) return dayjs(dateObject).format(format);
  return dayjs().format(format);
};

// export const getFormattedDate = (dateObject, format = 'MMM DD,YYYY') => {
//   if (dateObject) return dayjs(dateObject).format(format);
//   return '';
// };

export const getCurrentMonthDate = (date, format = 'MMM DD,YYYY') => {
  if (date) return dayjs().date(date).format(format);

  return dayjs().format(format);
};

export const getFormattedDateTime = (value = 0, unit = 'days', format = 'MMM DD,YYYY') => {
  if (value === 0) {
    return dayjs().format(format);
  } else {
    return dayjs().add(value, unit).format(format);
  }
};

export const timeFromNow = (date) => {
  const timestamp = dayjs(date).format('X');
  const newDate = dayjs.unix(timestamp);
  return dayjs(newDate).fromNow();
};

export const formatRelativeDate = (date, shortFormat = true) => {
  const parsedDate = dayjs(date + 'Z'); // Parse the input date

  if (shortFormat) {
    // Short format for both past and future dates
    const diffInMinutes = parsedDate.diff(dayjs(), 'minute'); // Difference in minutes

    // Handle "just now" case
    if (diffInMinutes === 0) {
      return 'just now';
    }

    if (Math.abs(diffInMinutes) < 60) {
      return diffInMinutes < 0 ? `${Math.abs(diffInMinutes)}m ago` : `in ${Math.abs(diffInMinutes)}m`;
    }

    const diffInHours = parsedDate.diff(dayjs(), 'hour'); // Difference in hours
    if (Math.abs(diffInHours) < 24) {
      return diffInHours < 0 ? `${Math.abs(diffInHours)}h ago` : `in ${Math.abs(diffInHours)}h`;
    }

    const diffInDays = parsedDate.diff(dayjs(), 'day'); // Difference in days
    const diffInMonths = parsedDate.diff(dayjs(), 'month'); // Difference in months

    // Changed this condition to handle full months only
    if (Math.abs(diffInMonths) < 1) {
      return diffInDays < 0 ? `${Math.abs(diffInDays)}d ago` : `in ${Math.abs(diffInDays)}d`;
    }

    if (Math.abs(diffInMonths) > 0 && Math.abs(diffInMonths) < 12) {
      return diffInMonths < 0 ? `${Math.abs(diffInMonths)}mo ago` : `in ${Math.abs(diffInMonths)}mo`;
    }

    const diffInYears = parsedDate.diff(dayjs(), 'year'); // Difference in years
    return diffInYears < 0 ? `${Math.abs(diffInYears)}y ago` : `in ${Math.abs(diffInYears)}y`;
  }

  // Long format for both past and future dates
  return parsedDate.fromNow();
};
