import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';

const CustomImageEditor = ({ image, updateImage, index, setDialogImagedEditor }) => {
  async function dataURItoBlob(dataURI, originalBlob) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let targetWidth = img.width;
        let targetHeight = img.height;
        const maxDimension = 1920;

        if (targetWidth > maxDimension || targetHeight > maxDimension) {
          const ratio = Math.min(maxDimension / targetWidth, maxDimension / targetHeight);
          targetWidth = Math.floor(targetWidth * ratio);
          targetHeight = Math.floor(targetHeight * ratio);
        }

        canvas.width = targetWidth;
        canvas.height = targetHeight;
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

        const originalSize = originalBlob.size;
        const originalType = originalBlob.type || 'image/jpeg';

        const findOptimalQuality = (maxQuality = 1.0, minQuality = 0.6, step = 0.02) => {
          let currentQuality = maxQuality;

          const tryQuality = (quality) => {
            canvas.toBlob(
              (blob) => {
                if (blob.size > originalSize && quality > minQuality) {
                  tryQuality(quality - step);
                } else {
                  resolve(blob);
                }
              },
              originalType,
              quality,
            );
          };

          tryQuality(currentQuality);
        };

        findOptimalQuality();
      };

      img.onerror = () => {
        console.error('Error loading image for processing');
        resolve(originalBlob);
      };

      img.src = dataURI;
    });
  }

  useEffect(() => {
    const handleRejection = (event) => {
      console.warn('Unhandled promise rejection:', event.promise, 'reason:', event);
    };

    window.addEventListener('unhandledrejection', handleRejection);
    return () => window.removeEventListener('unhandledrejection', handleRejection);
  }, []);

  return (
    <div style={{ width: '100%', height: '100%', zIndex: '1' }}>
      <FilerobotImageEditor
        source={image?.original || image?.url}
        onModify={(designState) => console.log('modify', designState)}
        onSave={async (editedImageObject, designState) => {
          try {
            const response = await fetch(image?.original || image?.url);
            const originalBlob = await response.blob();
            const blob = await dataURItoBlob(editedImageObject.imageBase64, originalBlob);
            if (blob) {
              updateImage(blob, index);
            }
          } catch (error) {
            console.error('Error processing image:', error);
          }
        }}
        closeAfterSave={true}
        showBackButton={true}
        disableSaveIfNoChanges={true}
        avoidChangesNotSavedAlertOnLeave={true}
        onBeforeSave={() => false}
        onClose={() =>
          setDialogImagedEditor({
            open: false,
            photo: null,
            index: null,
          })
        }
        useBackendTranslations={false}
        annotationsCommon={{ fill: '#ff0000' }}
        Rotate={{ angle: 90, componentType: 'buttons' }}
        tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.CROP]} // Ensure CROP tab is enabled
        defaultToolId={TOOLS.CROP} // Set default tool to CROP
        Crop={{
          ratio: 'custom',
          presetsItems: [],
          presetsFolders: [],
          enableFlexibleCrop: true, // Ensure free crop works
          minWidth: 50,
          minHeight: 50,
        }}
        savingPixelRatio={2} // Higher resolution
        previewPixelRatio={1}
        defaultSavedImageType='jpeg'
        defaultSavedImageQuality={1}
      />
    </div>
  );
};

CustomImageEditor.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number,
  updateImage: PropTypes.func,
  setDialogImagedEditor: PropTypes.func,
};

export default CustomImageEditor;
